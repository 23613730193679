import React, { Fragment } from "react"

import { Form, AlignRight, Button, Input, Textarea } from "../../utilities"

export const ContactForm = ({
  onSubmit = data => console.log({ data }),
  postFunc = () => {},
}) => (
  <Fragment>
    <Form
      state={{
        name: "",
        email: "",
        comments: "",
      }}
      required={["email", "name", "comments"]}
      onSubmit={onSubmit}
      postFunc={postFunc}
    >
      {({ isInvalid, state, handleChange }) => (
        <Fragment>
          <Input value={state.name} onChange={handleChange} name="name">
            Name *
          </Input>
          <Input
            type="email"
            value={state.email}
            name="email"
            onChange={handleChange}
          >
            Email *
          </Input>
          <Textarea
            name="comments"
            value={state.comments}
            onChange={handleChange}
          >
            What Can We Do For You? *
          </Textarea>
          <AlignRight>
            <Button disabled={isInvalid} modifiers={["primary", "block"]}>
              Contact Us
            </Button>
          </AlignRight>
        </Fragment>
      )}
    </Form>
  </Fragment>
)
