import React, { Fragment } from "react"

import styled from "styled-components"

import { Color, Type, Media } from "../../utilities"

const BannerContainer = styled.div`
  box-sizing: border-box;
  grid-column: 1 / 3;
  display: flex;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
  height: 100%;
`

const BannerText = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: ${Type.text};
  color: ${Color.white};
  max-width: 500px;
  flex-grow: 0;

  h1 {
    line-height: 110%;
    margin-bottom: 1em;
    font-size: 3.75rem;
    text-transform: uppercase;
  }

  p {
    font-family: ${Type.text};
    color: ${Color.white};
    line-height: 200%;
  }

  ${Media.below.tablet`
  padding-left: 0;
  padding-right: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`

export const Banner = ({ title, children }) => (
  <BannerContainer>
    <BannerText>
      <h1>{title}</h1>
      <div>{children}</div>
    </BannerText>
  </BannerContainer>
)
