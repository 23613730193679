import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { darken } from "polished";

import { Color, Wrapper, Flex, Media, Type, Grid, Col } from "../../utilities";

import tennis from "../../../images/tennis-ball.jpg";
import logo from "../../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

const StyledFooter = styled.div`
  color: ${Color.dark_blue};
  background-position: 90% center;
  background-repeat: no-repeat;
  background-image: url(${tennis});
  background-size: 385px;
  background-color: ${Color.white};
  font-family: ${Type.text};
  font-size: ${Type.basesize};
  padding: 4rem 0 8rem 0;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  h2 {
    color: ${Color.green};
    font-size: 2.25rem;
  }

  h3 {
    color: ${Color.brown};
    font-style: italic;
    font-size: 0.9rem;
    font-family: ${Type.text};
  }

  h4 {
    color: ${Color.dark_blue};
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  p,
  a {
    color: ${Color.light_blue};
    line-height: 150%;
    margin: 0;
  }
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${Flex} {
    justify-content: center;

    ${Media.below.tablet`
      display: block;
    `}
  }
`;
const InnerGrid = styled(Grid)`
  grid-template-columns: 250px 2fr;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`;

const FooterGrid = styled(Grid)`
  grid-template-columns: 1fr 2fr;

  .logo {
    max-width: 250px;
    width: auto;
    height: auto;
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    
    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 200px;
      width: auto;
      height: auto;
    }
  `}
`;

const NavItem = styled.div``;

const NavLink = styled(Link)`
  display: block;
  transition: all 0.3s ease;
  width: 100px;
  max-width: 400px;
  text-align: left;
  text-decoration: none;
  font-family: ${Type.text};
  color: ${Color.dark_blue} !important;
  font-weight: bold;
  font-size: 1.2rem;
  padding-bottom: 0.25rem;

  &:hover {
    color: ${darken(0.2, Color.dark_blue)};
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    text-align: center;
    margin: auto;
    `}

  ${(props) =>
    props.sub &&
    `
    color: ${Color.green} !important;
  `}
`;

const NavSubLink = styled(Link)`
  font-size: 0.9rem;
  color: ${Color.green} !important;
  display: block;
`;

const SiteMeta = styled.div`
  flex: 0 !important;
  flex-basis: 350px !important;

  h2 {
    width: 50%;
    line-height: 100%;
  }

  ${Media.below.tablet`
    margin: auto;
    text-align: center;

    h2 {
      width: 100%;
      line-height: 100%;
    }
    `}
`;

const FacebookLink = styled.a`
  &,
  svg {
    font-size: 2rem;
    width: 50px;
    color: ${Color.light_blue};

    &:hover {
      color: ${Color.dark_blue};
    }
  }
`;

const footerNav = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Work",
    url: "/work",
  },
  {
    sub: [
      {
        title: "Integrated Campaigns",
        url: "/",
      },
      {
        title: "Branding",
        url: "/work/branding/",
      },
      {
        title: "Newsletters",
        url: "/work/newsletters/",
      },
      {
        title: "Direct Marketing",
        url: "/work/direct-marketing",
      },
      {
        title: "Ads",
        url: "/work/ads",
      },
      {
        title: "Print",
        url: "/work/print",
      },
      {
        title: "Websites",
        url: "/work/websites",
      },
      {
        title: "Videos",
        url: "/work/videos",
      },
      {
        title: "Events",
        url: "/work/events",
      },
    ],
  },
];

const Footer = ({
  phone_number,
  email,
  address_one,
  address_two,
  slogan,
  company_name,
}) => {
  return (
    <StyledFooter>
      <Wrapper>
        <Flex>
          <FooterGrid>
            <img className="logo" src={logo}></img>
            <SiteMeta>
              <h2>{slogan}</h2>
              <InnerGrid>
                <Col>
                  {footerNav.map((item, ix) => (
                    <NavItem key={ix}>
                      <NavLink sub={item.sub ? true : false} to={item.url}>
                        {item.title}
                      </NavLink>
                      {item.sub && (
                        <div>
                          {item.sub.map((item, ix) => (
                            <NavSubLink to={item.url} key={ix}>
                              {item.title}
                            </NavSubLink>
                          ))}
                        </div>
                      )}
                    </NavItem>
                  ))}
                </Col>

                <Col>
                  <h3>Contact</h3>
                  <h4>{company_name}</h4>
                  <p>{address_one}</p>
                  <p>{address_two}</p>
                  <br />
                  <h4>{phone_number}</h4>
                  <a href={`mailto:${email}`}>{email}</a>
                  <br />
                  <br />
                  <h4>Social</h4>
                  <FacebookLink
                    href="https://www.facebook.com/Venzel-Communications-327848820607022"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </FacebookLink>
                </Col>
              </InnerGrid>
            </SiteMeta>
          </FooterGrid>
        </Flex>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;
