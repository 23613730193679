import React, { useState } from "react";
import { darken } from "polished";
import { Link } from "gatsby";
import styled from "styled-components";

import { Color, Wrapper, Type, Media, Grid } from "../../utilities";

import logo from "../../../images/logo.png";

const HeaderContainer = styled.div`
  font-family: ${Type.header};
  background-size: cover;
  background-color: #fff;
  background-position: center;
  ${(props) => props.image && `background-image: url('${props.image}');`}
  h1 {
    color: ${Color.white};
  }

  ${Wrapper} {
    ${(props) =>
      !props.internal &&
      `
      min-height: 80vh;
    `}
  }
`;

const Logo = styled(Link)`
  position: relative;
  top: 1rem;
  display: inline-block;
  transition: all 0.3s ease;

  .logo {
    max-width: 250px;
  }

  &:hover {
    transform: scale(1.1);
  }

  ${Media.below.tablet`
    height: auto;
    margin-left: auto;
    text-align: center;
    .logo {
    max-width: 200px;
  }
  `}
`;

const HeaderGrid = styled(Grid)`
  height: 100%;
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-columns: 1.5fr 2fr;
  ${Media.below.tablet`
    display: block;
    text-align: center;
  `}

  ${(props) =>
    !props.internal &&
    `
    min-height: 80vh;
  `}
`;

const WhiteBox = styled.div`
  display: inline-block;
  background-color: #fff;
  width: 35%;
  max-width: 400px;
  max-height: 60px;
  text-align: left;
  padding: 1rem 1.5rem;
  border-radius: 0px 0px 15px 15px;
  font-family: ${Type.text};
  font-size: ${Type.basesize};

  h4 {
    color: ${Color.dark_blue};
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  p,
  a {
    color: ${Color.dark_blue};
  }
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${Media.below.tablet`
    display: none;
  `}
`;

const ContactAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  ${(props) =>
    props.internal
      ? `flex-direction: row-reverse; align-items: flex-start;`
      : ""};
`;

const ExitButton = styled.span`
  position: absolute;
  z-index: 9001;
  right: 0px;
  top: 0px;
  font-size: 2rem;
  height: 40px;
  width: 40px;
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 5px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  background: ${Color.white};
  border-radius: 100%;

  ${Media.below.tablet`
    display: flex;
  `}
`;

const Burg = styled.div`
  display: none;
  z-index: 101;
  position: absolute;
  left: 0px;
  width: 32px;
  height: 5px;
  top: 8%;
  left: 8%;
  cursor: pointer;
  background: ${Color.dark_blue};

  &::before,
  &::after {
    left: 0;
    content: "";
    margin-top: -12px;
    position: absolute;
    width: 100%;
    height: 5px;
    background: ${Color.dark_blue};
  }

  &::after {
    margin-top: 12px;
  }

  ${Media.below.tablet`
    display: block;
  `}
`;

const NavItem = styled.div`
  width: 150px;
  padding: 0.5rem;
  padding-right: 0;
  text-align: left;
`;

const NavLink = styled(Link)`
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: ${Type.text};
  color: ${Color.green};
  font-size: 1.5rem;

  &:hover {
    color: ${darken(0.2, Color.green)};
  }

  h4 {
    margin-bottom: 0;
  }

  ${Media.below.tablet`
    width: auto;
    display: block;
    text-align: center;
    padding: 2rem;
    font-size: 3rem;
  `}
`;

const NavUL = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 0;
  ${Media.below.tablet`
    margin: 0;
    display: ${(props) => (props.on ? `flex` : `none`)};
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
  `}

  ${(props) =>
    props.internal
      ? `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  `
      : `
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
    padding: 1rem;
    padding-bottom: 0.5rem;
  `}
`;

const Header = ({
  image,
  internal,
  renderBanner = null,
  phone_number,
  email,
  nav,
}) => {
  const [on, setOn] = useState(false);

  return (
    <HeaderContainer image={image} internal={internal}>
      <Wrapper>
        <HeaderGrid internal={internal}>
          <div>
            <Burg onClick={() => setOn(!on)} />
            <Logo to="/">
              <img className="logo" src={logo} />
            </Logo>
          </div>
          <ContactAlign internal={internal}>
            <WhiteBox>
              <h4>{phone_number}</h4>
              <a href={`mailto:${email}`} target="_blank">
                {email}
              </a>
            </WhiteBox>

            <NavUL on={on} internal={internal}>
              <ExitButton onClick={() => setOn(!on)}>x</ExitButton>
              {nav.map((item, ix) => (
                <NavItem>
                  <NavLink key={ix} to={item.slug}>
                    {item.title}
                  </NavLink>
                </NavItem>
              ))}
            </NavUL>
          </ContactAlign>
          {typeof renderBanner === "function" && renderBanner()}
        </HeaderGrid>
      </Wrapper>
    </HeaderContainer>
  );
};

export default Header;
