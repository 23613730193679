import React, { Fragment } from "react";
import * as moment from "moment";

import { ApiContextConsumer } from "../ApiContext";
import { ErrorLoadBlock } from "../ErrorLoadBlock";

export const QueryRender = ({ error, loading, result, children }) => (
  <Fragment>
    <ErrorLoadBlock loading={loading} error={error} />
    {result.data && (
      <Fragment>
        {typeof children === "function" ? children({ result }) : children}
      </Fragment>
    )}
  </Fragment>
);

class QueryContext extends React.Component {
  static defaultProps = {
    query: () => {},
    variables: null,
    queryQueue: [],
    id: null,
    prerender: false,
    apiStock: {},
  };

  state = {
    loading: false,
    error: null,
    result: [],
    unmounted: false,
    reload: false,
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(oldProps) {
    const { queryQueue, id } = this.props;
    if (
      queryQueue !== oldProps.queryQueue &&
      queryQueue.includes(id) &&
      !this.state.unmounted &&
      !this.state.reload
    ) {
      this.setState({
        ...this.state,
        reload: true,
      });

      this.initFetch();
    }
  }

  // Cache Stock
  bottleNecking = async () => {
    const { apiStock, id } = this.props;
    const inStock = Object.keys(apiStock).find((sid) => sid === id);

    // Out Of Stock
    if (typeof inStock === "undefined") {
      return this.fetchData();
    }

    // Check Timestamp
    const item = apiStock[id];
    const compare = moment().diff(item.timestamp, "seconds");

    // Greater Than 3 Seconds Cache Then Call API
    if (Number(compare) > 3) {
      return this.fetchData();
    }

    return;
  };

  fetchData = async () => {
    const { query, variables, addToStock, id } = this.props;
    try {
      addToStock({
        id,
        data: { timestamp: new Date(), loading: true },
      });

      const result = await query(variables);

      addToStock({
        id,
        data: { timestamp: new Date(), loading: false, error: null, result },
      });
    } catch (error) {
      if (!this.state.unmounted) {
        addToStock({
          id,
          data: { timestamp: new Date(), loading: false, error },
        });
      }
    }
  };

  initFetch = async () => {
    const { queryQueue, id, removeFromQueue } = this.props;

    // Loading Queue
    if (!this.state.unmounted) {
      this.setState({
        ...this.state,
        loading: true,
      });
    }

    // Caching Feature
    await this.bottleNecking();

    // Remove From ID
    if (queryQueue.includes(id)) {
      removeFromQueue(id);

      // Remove Reload State Component
      if (!this.state.unmounted && this.state.reload) {
        this.setState({
          ...this.state,
          reload: false,
        });
      }
    }
  };

  getStock = () => {
    const { apiStock, id } = this.props;
    const initReturn = {
      loading: false,
      error: null,
      result: [],
    };
    const inStock = Object.keys(apiStock).find((sid) => sid === id);
    return inStock ? { ...initReturn, ...apiStock[id] } : initReturn;
  };

  render() {
    const { children, prerender } = this.props;
    const { loading, error, result } = this.getStock();
    return (
      <Fragment>
        {prerender ? (
          <QueryRender loading={loading} error={error} result={result}>
            {children({ loading, error, result })}
          </QueryRender>
        ) : (
          children({ loading, error, result })
        )}
      </Fragment>
    );
  }
}

export const Query = (props) => (
  <ApiContextConsumer>
    {(context) => <QueryContext {...props} {...context} />}
  </ApiContextConsumer>
);
