import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Section } from "../Section";
import { Type, Color, Media } from "../../utilities";

const StyledSection = styled.div`
  font-family: ${Type.text};
  font-size: ${Type.basesize};
`;

const Image = styled.div`
  transition: all 0.5s ease;
  background-size: cover;
  background-position: center center;
  background-image: ${(props) =>
    props.image ? `url('${props.image}')` : "none"};
  height: 100%;
`;

const PlaceholderStyle = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;

  &:hover {
    ${Image} {
      transform: scale(1.5) rotate(10deg);
    }
  }
`;

const ImagePlaceholder = ({ image }) => (
  <PlaceholderStyle>
    <Image image={image} />
  </PlaceholderStyle>
);

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  ${Media.below.tablet`
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
    justify-content: center;
  `}
`;

const Header = styled.div`
  font-family: ${Type.header};
  font-size: 2rem;
  color: ${Color.green};
  line-height: 200%;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all 0.1s ease;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }

  &::after {
    content: " ";
    height: 6px;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: ${Color.green};
    transition: all 0.2s ease;
  }
`;

const CategoryItem = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: space-between;

  &:hover {
    text-decoration: none !important;
    ${Header} {
      &::after {
        width: 100%;
      }
    }
  }
`;

const CategoryText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Color.text};
  text-decoration: none;
  margin-bottom: 1rem;

  &:hover {
    text-decoration: none !important;
  }
`;

const convertUrl = (url) => url.replace(`https://venzelcommunications.com`, "");

export const WorkCategories = ({ categories }) => {
  return (
    <Section>
      <StyledSection>
        <CategoryGrid>
          {categories.map((item) => (
            <CategoryItem to={convertUrl(item.node.workCategoryFields.page)}>
              <CategoryText>
                <Header>{item.node.title}</Header>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.node.content,
                  }}
                ></div>
              </CategoryText>
              <ImagePlaceholder
                image={
                  item.node.featuredImage
                    ? item.node.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData.images.fallback.src
                    : null
                }
              />
            </CategoryItem>
          ))}
        </CategoryGrid>
      </StyledSection>
    </Section>
  );
};
