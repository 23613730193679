import React from "react"

import styled from "styled-components"

import { Type, Color, Media, Wrapper } from "../../utilities"

const StyledSection = styled.div`
  font-family: ${Type.text};
  font-size: ${Type.basesize};
  padding: 2em 4.5em 2em;
  background-size: cover;
  background-color: #efefef;
  ${Media.below.tablet`
    left: 0px;
    right: 0px;
  `}
`
const Header = styled.div`
  font-family: ${Type.header};
  font-size: 2rem;
  color: ${Color.dark_blue};
  padding-bottom: 2rem;
  line-height: 120%;
`

const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${Media.below.tablet`
    display: grid;
    grid-template-columns: 1fr;
  `}
`

const OverviewItem = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 3px solid ${Color.white};
  border-bottom: 3px solid ${Color.white};
  padding: 2rem;

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-child(n + 4) {
    border-bottom: none;
  }

  ${Media.below.tablet`
    border-bottom: 3px solid ${Color.white};
    border-right: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    &:nth-child(n + 4) {
    border-bottom: 3px solid ${Color.white};
    }
    &:last-child{
      border-bottom: none;
    }
  `}
`

const OverviewText = styled.div`
  display: flex;
  flex-direction: column;
`

export const OverviewSection = ({ blocks }) => (
  <StyledSection>
    <Wrapper>
      <OverviewGrid>
        {blocks.map((item, ix) => (
          <OverviewItem key={ix}>
            <OverviewText>
              <Header>{item.node.title}</Header>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.node.content,
                }}
              ></div>
            </OverviewText>
          </OverviewItem>
        ))}
      </OverviewGrid>
    </Wrapper>
  </StyledSection>
)
