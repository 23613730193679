import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Color, Media, Embed } from "../../utilities";

const SliderContainer = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.2s ease;
  height: 700px;

  ${Media.below.tablet`
    height: 500px;
  `}
`;

const Slides = styled.div`
  position: relative;
  height: 100%;
`;

const SlideContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 100;

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    z-index: 300;
  `}
`;

const SlideImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const SlideBody = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Slide = ({ slide, active }) => (
  <SlideContainer active={active}>
    <SlideBody>
      {slide.showcaseBlocks.mediaVideo ? (
        <Embed
          src={slide.showcaseBlocks.mediaVideo}
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <SlideImage
          src={
            slide.showcaseBlocks.mediaImage
              ? slide.showcaseBlocks.mediaImage.localFile.childImageSharp
                  .gatsbyImageData.images.fallback.src
              : null
          }
        />
      )}
    </SlideBody>
  </SlideContainer>
);

const Arrow = styled.div`
  border: 20px ${Color.grey} solid;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: rotate(135deg);
  margin-top: -10px;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1000;

  &:hover {
    opacity: 1;
    transform: rotate(135deg) scale(1.5);
  }

  ${Media.below.tablet`
    display: none;
  `}
`;

const RightArrow = styled(Arrow)`
  right: 5%;
  left: auto;
  transform: rotate(-45deg);

  &:hover {
    transform: rotate(-45deg) scale(1.5);
  }
`;

// Changing Slide
const changeSlide = ({ slides, setSlide, add = true }) =>
  setSlide((prevSlide) => {
    if (add) {
      return prevSlide + 1 >= slides.length ? 0 : prevSlide + 1;
    } else {
      return prevSlide - 1 < 0 ? slides.length - 1 : prevSlide - 1;
    }
  });

export const ShowcaseSlider = ({ slides, change }) => {
  const [current, setSlide] = useState(0);
  useEffect(() => {
    if (change.count !== 0) {
      setSlide(change.slide);
    }
  }, [change.count]);

  return (
    <SliderContainer>
      <Arrow
        onClick={() =>
          changeSlide({
            setSlide,
            current,
            slides,
            add: false,
          })
        }
      />
      <RightArrow onClick={() => changeSlide({ setSlide, current, slides })} />
      <Slides>
        {slides.map((slide, ix) => (
          <Slide active={current === ix} slide={slide.node} key={ix} />
        ))}
      </Slides>
    </SliderContainer>
  );
};
