export const Color = {
  dark_blue: "#003b49",
  light_blue: "#468ac9",
  brown: "#6b4326",
  green: "#7ac14e",
  text: "#221f20",
  red: "#f02e2e",
  grey: "#666666",
  yellow: "#fceb26",
  white: "#fff",
  black: "#000",
}
