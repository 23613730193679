import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../Header";
import Footer from "../Footer";
import { GlobalStyles, OverviewSection } from "../../elements";
import { Color, SEO } from "../../utilities";

const Body = styled.div`
  a {
    color: ${Color.dark_blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const fixContentLinks = (content) => {
  const regex = /^((http|https|ftp):\/\/)/;
  if (regex.test(content)) {
    content = content.replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    );
  }

  return content;
};

const nav = [
  {
    title: "Work",
    slug: "/work",
  },
  {
    title: "About",
    slug: "/about",
  },
  {
    title: "Contact",
    slug: "/contact",
  },
];

const Layout = ({
  children,
  image = null,
  internal = false,
  renderBanner,
  title = null,
  description = null,
}) => (
  <Fragment>
    <SEO title={title} description={description} />
    <GlobalStyles />
    <StaticQuery
      query={graphql`
        query ConfigQuery {
          allWpConfig {
            edges {
              node {
                configFields {
                  phoneNumber
                  email
                  addressOne
                  addressTwo
                  slogan
                  companyName
                }
              }
            }
          }
          allWpOverviewSection(
            sort: { fields: overviewSectionFields___order, order: ASC }
          ) {
            edges {
              node {
                id
                slug
                title
                content
              }
            }
          }
        }
      `}
      render={({ allWpConfig, allWpOverviewSection }) => (
        <Fragment>
          <Header
            image={image}
            internal={internal}
            renderBanner={renderBanner}
            phone_number={allWpConfig.edges[0].node.configFields.phoneNumber}
            email={allWpConfig.edges[0].node.configFields.email}
            nav={nav}
          />
          <Body>{children}</Body>
          <OverviewSection blocks={allWpOverviewSection.edges} />
          <Footer
            phone_number={allWpConfig.edges[0].node.configFields.phoneNumber}
            company_name={allWpConfig.edges[0].node.configFields.companyName}
            email={allWpConfig.edges[0].node.configFields.email}
            address_one={allWpConfig.edges[0].node.configFields.addressOne}
            address_two={allWpConfig.edges[0].node.configFields.addressTwo}
            slogan={allWpConfig.edges[0].node.configFields.sloga}
          />
        </Fragment>
      )}
    />
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
