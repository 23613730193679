import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { lighten, darken } from "polished"

import { Type, Color } from "../"

const BUTTON_MODIFERS_CONFIG = {
  primary: () => `
    background: ${Color.dark_blue};
    color: ${Color.white} !important;
    margin: 0 auto;
    display: block;

    &:hover {
      background: ${lighten(0.2, Color.dark_blue)};
      text-decoration: none !important;
    }
    `,

  warning: () => `
    background: ${Color.white}
    color: ${Color.yellow} !important;
    border: 3px solid ${Color.yellow};
    margin: 0 auto;
    display: block;

    &:hover {
      background: ${darken(0.1, Color.yellow)} !important;
      text-decoration: none !important;
    }
  `,

  danger: () => `
    color: ${Color.red} !important;
    background: ${Color.white};
    border: 3px solid ${Color.red};
    margin: 0 auto;
    display: block;

    &:hover {
      background: ${lighten(0.1, Color.red)};
      text-decoration: none !important;
    }
  `,

  large: () => `
    padding: 1.5rem 4rem;
    font-size: 2rem;
    color: ${Color.red};
    background: ${Color.white};
    border: 3px solid ${Color.red};
    margin: 0 auto;
    display: block;

    &:hover {
      background: ${lighten(0.1, Color.red)};
    }
  `,

  small: () => `
    padding: 0.8rem 1rem
    font-size: 1rem;
  `,

  block: () => `
    margin: 0 !important;
    width: 100%;
    text-align: center;
  `,
}

const buttonStyles = css`
  padding: 1.5rem 3rem;
  font-size: 1.2rem;
  border: 0;
  font-family: ${Type.header};
  font-weight: 100 !important;
  text-align: center;
  text-decoration: none !important;
  box-sizing: border-box;
  transition: all 0.4s ease;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none !important;
    color: ${Color.white} !important;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  ${applyStyleModifiers(BUTTON_MODIFERS_CONFIG)};
`

export const Button = styled.button`
  ${buttonStyles}
`
export const ButtonA = styled.a`
  ${buttonStyles}
`

export const ButtonLink = styled(Link)`
  ${buttonStyles};
`
